import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Inner from '../../components/Transition/Inner';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MdLaptop, MdOutlinePhonelink } from 'react-icons/md';
import { service1, service2, service3 } from '../../assets';
import { Footer } from '../../components';
import {
  expandFromLeft,
  fadeInUpDelay,
  fadeInUpDelay2,
  fadeInUpItem,
} from '../../components/Transition';
import { motion } from 'framer-motion';
import './Services.scss';

const ServicesScroller = () => {
  const service1Ref = useRef(null);
  const service2Ref = useRef(null);
  const service3Ref = useRef(null);

  const serviceList = [
    {
      title: 'Free Consultation',
      icon: <MdOutlinePhonelink className='service-icon' />,
      iconText: 'Online | Phone',
      price: 'Free',
      desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Adipisci, ullam in possimus rem esse nisi voluptatum id.  Accusantium, sapiente deleniti eos asperiores nesciunt, pariatur cumque expedita perferendis, placeat eligendi nihil!',
      image: true,
      ref: service1Ref,
    },
    {
      title: 'Individual Counselling',
      icon: <MdOutlinePhonelink className='service-icon' />,
      iconText: 'Online | Phone',
      price: '45 minutes - $120 + HST',
      desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Adipisci, ullam in possimus rem esse nisi voluptatum id.  Accusantium, sapiente deleniti eos asperiores nesciunt, pariatur cumque expedita perferendis, placeat eligendi nihil!',
      image: false,
      ref: service2Ref,
    },
    {
      title: 'Couples Therapy',
      icon: <MdLaptop className='service-icon' />,
      iconText: 'Online',
      price: '45 minutes - $140 + HST',
      desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Adipisci, ullam in possimus rem esse nisi voluptatum id.  Accusantium, sapiente deleniti eos asperiores nesciunt, pariatur cumque expedita perferendis, placeat eligendi nihil!',
      image: false,
      ref: service3Ref,
    },
  ];

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
      trigger: service1Ref.current,
      start: 'top',
      end: 'bottom',
      scrub: true,
      onEnterBack: () => changeImage(service1),
    });

    ScrollTrigger.create({
      trigger: service2Ref.current,
      start: 'top-=460px',
      scrub: true,
      onEnter: () => changeImage(service2),
      onEnterBack: () => changeImage(service2),
    });

    ScrollTrigger.create({
      trigger: service3Ref.current,
      start: 'top-=460px',
      scrub: true,
      onEnter: () => changeImage(service3),
      onEnterBack: () => changeImage(service3),
    });
  }, []);

  useLayoutEffect(() => {
    const initializeScrollTriggers = () => {
      gsap.registerPlugin(ScrollTrigger);

      gsap.to('.secondSection-service-img', {
        scrollTrigger: {
          trigger: '.secondSection-service-img',
          start: 'top-=300px',
          endTrigger: '.secondSection-services',
          end: 'bottom-=1020px',
          pin: true,
          pinSpacing: false,
          scrub: true,
        },
      });

      // Refresh ScrollTriggers after a delay
      setTimeout(() => ScrollTrigger.refresh(), 500);
    };

    // Check if all images are loaded
    const images = document.querySelectorAll('.secondSection-service-img img');
    let imagesLoaded = 0;
    images.forEach((img) => {
      img.onload = () => {
        imagesLoaded++;
        if (imagesLoaded === images.length) {
          initializeScrollTriggers();
        }
      };
      // If image is already loaded in cache
      if (img.complete) {
        img.onload();
      }
    });

    // Cleanup function
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  const changeImage = (newImageSrc) => {
    const container = document.querySelector('.secondSection-service-img');
    const currentImg = container.querySelector('img');
    const newImg = document.createElement('img');

    if (newImg.src !== null) {
      newImg.src = newImageSrc;
      newImg.style.position = 'absolute';
      newImg.style.top = '0';
      newImg.style.left = '0';
      newImg.style.width = '100%';
      newImg.style.height = 'auto'; // Maintain aspect ratio
      newImg.style.minHeight = '600px';
      newImg.style.objectFit = 'cover'; // Prevent stretching
      newImg.style.maxHeight = '100%'; // Do not exceed the container's bounds
      newImg.style.opacity = '0'; // Start fully transparent
      newImg.style.borderRadius = '1.5rem';

      container.appendChild(newImg);

      // Use GSAP to crossfade the opacity of the images
      gsap.to(newImg, {
        opacity: 1,
        duration: 0.5,
        onComplete: () => {
          // Remove the old image
          if (currentImg) {
            currentImg.remove();
          }
        },
      });

      gsap.to(currentImg, {
        opacity: 0,
        duration: 0.5,
      });
    }
  };

  return (
    <div className='secondSection-services-wrapper'>
      <div className='secondSection-services-container container'>
        <div className='secondSection-services'>
          {serviceList?.map((service) => (
            <div className='secondSection-service ' ref={service?.ref}>
              <motion.div
                className='secondSection-service-heading'
                variants={service?.image ? fadeInUpDelay2 : ''}
                initial='initial'
                whileInView='whileInView'
                viewport={{ once: true }}
              >
                <motion.div variants={fadeInUpItem}>
                  <h2>{service?.title}</h2>
                </motion.div>
                <motion.div
                  className='secondSection-service-heading-icon-pricing-wrapper'
                  variants={fadeInUpItem}
                >
                  <div className='secondSection-service-heading-icon-wrapper'>
                    {service?.icon}
                    <h3>{service?.iconText}</h3>
                  </div>
                  <div className='secondSection-service-pricing'>
                    <h3>{service?.price}</h3>
                  </div>
                </motion.div>
                <motion.div variants={fadeInUpItem}>
                  <p>{service?.desc}</p>
                </motion.div>
              </motion.div>

              {service?.image && (
                <div className='secondSection-service-img'>
                  <motion.img
                    src={service1}
                    alt='Service'
                    variants={fadeInUpDelay2}
                    initial='initial'
                    whileInView='whileInView'
                    viewport={{ once: true }}
                    loading='lazy'
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Services = () => {
  const secondSec = useRef(null);

  const [footerOnTop, setFooterOnTop] = useState(false);

  // Scroll to top of page on render
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: document.documentElement,
        start: 'top+=3200px',
        end: '+=200px',
        scrub: true,
      },
    });

    timeline.to(secondSec.current, { width: '98%' });
  }, []);

  return (
    <Inner>
      <div className='services-container' ref={secondSec}>
        <div className='services-header  container'>
          <motion.div
            className='services-line'
            variants={expandFromLeft}
            initial='initial'
            whileInView='whileInView'
            viewport={{ once: true }}
          />
          <motion.div
            className='services-heading'
            variants={fadeInUpDelay}
            initial='initial'
            whileInView='whileInView'
            viewport={{ once: true }}
          >
            Services
          </motion.div>
        </div>

        <ServicesScroller />
      </div>
      <Footer isOnTop={footerOnTop} />
    </Inner>
  );
};

export default Services;
