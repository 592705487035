import logo from './images/logo-concept3_white.svg';
import logo2 from './images/logo-concept3.svg';
import profile from './images/profile.jpg';
import profile2 from './images/profile2.jpg';
import service1 from './images/service-1.webp';
import service2 from './images/service-2.webp';
import service3 from './images/service-3.webp';
import homeImage from './images/home-img.webp';
import homeImage2 from './images/mainTreeImg2.webp';
import chairs from './images/chairs.svg';
import heads from './images/heads.svg';
import forestBg from './videos/forest1.mp4';
import plant from './videos/plant.mp4';

export {
  forestBg,
  logo,
  logo2,
  profile,
  profile2,
  service1,
  service2,
  service3,
  homeImage,
  homeImage2,
  plant,
  chairs,
  heads,
};
