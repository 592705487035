import React from 'react';
import './Footer.scss';
import { logo } from '../../assets';
import { AnimatePresence, motion } from 'framer-motion';
import { fadeIn, fadeInFooter, fadeInVideo } from '../Transition';

const Footer = ({ isOnTop }) => {
  return (
    <AnimatePresence>
      <div className='bottomContent-top' key='foot1'></div>
      <div
        className='bottomContent-spacer mobileSmDisplayNone'
        key='foot2'
      ></div>
      <motion.div
        className={`bottomContent ${isOnTop ? 'onTop' : ''}`}
        variants={fadeInFooter}
        initial='initial'
        exit='initial'
        animate='animate'
        key='foot3'
      >
        <div className='bottomContent-content-top container'>
          <div className='bottomContent-content-top-heading'>
            <h2>
              A <span>healthier you</span>
              <br />
              starts here
            </h2>
          </div>
          <div className='bottomContent-content-top-button'>
            <button>Book a Consultation</button>
          </div>
        </div>
      </motion.div>

      <div className='bottomContent-content-bottom' key='foot4'>
        <div className='bottomContent-content-bottom-wrapper container'>
          <div className='bottomContent-content-bottom-left'>
            <div className='bottomContent-content-bottom-logo'>
              <img src={logo} alt='logo' loading='lazy' />
            </div>
            <div className='bottomContent-content-bottom-copyright'>
              <h3>
                {new Date().getFullYear()} Wisdomway Wellness. All Rights
                Reserved.
              </h3>
            </div>
          </div>
          <div className='bottomContent-content-bottom-right mobileSmDisplayNone'>
            <div className='bottomContent-content-bottom-socials'>
              <div className='bottomContent-content-bottom-socials-website'>
                <h3>maha@wisdomwaywellness.com</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default Footer;
