import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Footer, TestimonialsCarousel } from '../../components';
import {
  bg3,
  chairs,
  forestBg,
  heads,
  homeImage,
  homeImage2,
  plant,
  service1,
  service2,
  service3,
} from '../../assets';
import {
  motion,
  useMotionTemplate,
  useScroll,
  useTransform,
} from 'framer-motion';
import {
  expandFromLeft,
  fadeInUpDelay,
  fadeInVideo,
} from '../../components/Transition';
import { HiOutlineArrowLongRight } from 'react-icons/hi2';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type';
import Inner from '../../components/Transition/Inner';
import { MdLaptop, MdOutlinePhonelink } from 'react-icons/md';
import './Home.scss';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Reveal from '../../utils/Reveal';
import { Helmet } from 'react-helmet-async';
import { useGSAP } from '@gsap/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Quote = () => (
  <motion.div
    className='quote-container'
    initial={{ height: 0, padding: 0 }}
    whileInView={{
      height: '600px',
      padding: '30px',
      transition: {
        duration: 1.5,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    }}
    viewport={{ once: true }}
  >
    <motion.div
      className='quote-wrapper container'
      initial={{ opacity: 0 }}
      whileInView={{
        opacity: 1,
        transition: {
          duration: 1.5,
          ease: [0.6, -0.05, 0.01, 0.99],
        },
      }}
      viewport={{ once: true }}
    >
      <div className='quote'>
        <h2>“Who looks outside, dreams. Who looks inside awakens”</h2>
      </div>
      <div className='quote-author'>
        <h3>- Carl Jung</h3>
      </div>
    </motion.div>
  </motion.div>
);

const FaqComponent = () => {
  const faqList = [
    {
      id: 1,
      question: 'Are services covered by insurance?',
      desc: 'Many insurances have psychotherapy sessions covered as part of their extended health care plan. It is best to get in touch with your insurance provider and ask them about details of coverage.',
    },
    {
      id: 2,
      question: 'How does virtual counselling work?',
      desc: 'We will use safe, password protected, and encrypted programs to connect with each other via google meet or zoom (However, you are comfortable).',
    },
    {
      id: 3,
      question: 'Do you offer sliding scale?',
      desc: 'Yes, please get in touch to see if you qualify.',
    },
    {
      id: 4,
      question: 'Do you offer in person sessions?',
      desc: 'At this time, we only offer virtual sessions.',
    },
    {
      id: 5,
      question: 'What is your cancellation policy?',
      desc: 'I require at least 24 hours notice for cancellations or else late fees will apply. However, I understand sometimes emergencies happen unexpectedly so please get in touch with me as soon as possible and we can work something out.',
    },
    {
      id: 6,
      question: 'Do you take cheques?',
      desc: 'No, we accept e-transfers, and that is to be paid before the session starts.',
    },
  ];

  useEffect(() => {
    const accordions = [...document.querySelectorAll('.acc-list')];

    accordions.forEach((acc, idx) => {
      acc.addEventListener('click', () => {
        const panel = acc.nextElementSibling;

        if (panel.style.maxHeight) {
          panel.classList.remove('active');
          panel.style.maxHeight = null;
        } else {
          panel.classList.add('active');
          panel.style.maxHeight = '900px';
        }
      });
    });
  }, []);

  return (
    <div className='accordion-container container'>
      <div className='accordion-header container'>
        <motion.div
          className='accordion-heading'
          variants={fadeInUpDelay}
          initial='initial'
          whileInView='whileInView'
          viewport={{ once: true }}
        >
          FAQ
        </motion.div>
      </div>

      {faqList?.map((faq) => (
        <motion.div
          className='container'
          key={faq?.id}
          variants={fadeInUpDelay}
          initial='initial'
          whileInView='whileInView'
          viewport={{ once: true }}
        >
          <button className='accordion acc-list'>{faq?.question}</button>
          <div className='accordion-panel'>
            <p>{faq?.desc}</p>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

const Services = () => {
  const serviceList = [
    {
      title: 'Free Consultation',
      location: 'Online | Phone',
      price: 'Free',
      description:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Adipisci, ullam in possimus rem esse nisi voluptatum id. Accusantium, sapiente deleniti eos asperiores nesciunt, pariatur cumque expedita perferendis, placeat eligendi nihil!',
      image: service1,
      hr: false,
      icon: <MdOutlinePhonelink className='service-icon' />,
    },
    {
      title: 'Individual Counselling',
      location: 'Online | Phone',
      price: '$120/45 minutes',
      description:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Adipisci, ullam in possimus rem esse nisi voluptatum id. Accusantium, sapiente deleniti eos asperiores nesciunt, pariatur cumque expedita perferendis, placeat eligendi nihil!',
      image: service2,
      hr: true,
      icon: <MdOutlinePhonelink className='service-icon' />,
    },
    {
      title: 'Couples Therapy',
      location: 'Online',
      price: '$140/45 minutes',
      description:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Adipisci, ullam in possimus rem esse nisi voluptatum id. Accusantium, sapiente deleniti eos asperiores nesciunt, pariatur cumque expedita perferendis, placeat eligendi nihil!',
      image: service3,
      hr: true,
      icon: <MdLaptop className='service-icon' />,
    },
  ];

  const servicesContainerRef = useRef(null);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const services = document.querySelectorAll('.services-service');

    services.forEach((service) => {
      const arrow = service.querySelector(
        '.services-service-button-inner-arrow-right'
      );
      const hoverArrow = service.querySelector(
        '.services-service-button-inner-arrow-right-hover'
      );

      const textElements = service.querySelectorAll(
        '.services-service-title, .services-service-text p, .services-service-button button, .services-service-title-location-icon, .services-service-title-location, .services-service-price'
      );
      const imageElement = service.querySelector('.services-service-image img');

      service.addEventListener('mouseenter', () => {
        // Kill any ongoing animations on text elements
        gsap.killTweensOf(textElements);

        gsap.to(arrow, { x: 30, opacity: 0, duration: 0.1 }); // Move right and fade out
        gsap.fromTo(
          hoverArrow,
          { x: -30, opacity: 0 },
          { x: 0, opacity: 1, duration: 0.1 }
        );

        // Start new animations
        gsap.to(service, {
          duration: 0.08,
          ease: 'expo.inOut',
          '--after-scale': '1',
          '--after-opacity': '1',
        });
        gsap.to(textElements, {
          color: '#fff',
          duration: 0.1,
          delay: 0.15,
        });
        gsap.to(imageElement, {
          scale: 1.1,
          duration: 0.35,
          ease: 'expo.inOut',
        });
      });

      service.addEventListener('mouseleave', () => {
        // Kill any ongoing animations on text elements
        gsap.killTweensOf(textElements);

        gsap.to(arrow, { x: 0, opacity: 1, duration: 0.1 }); // Return to center and fade in
        gsap.to(hoverArrow, { x: -30, opacity: 0, duration: 0.1 }); // Move left and fade out

        // Start new animations
        gsap.to(service, {
          duration: 0.07,
          ease: 'expo.inOut',
          '--after-scale': '0',
          '--after-opacity': '0',
        });
        gsap.to(textElements, {
          color: '#777777',
          duration: 0.07,
        });
        gsap.to(imageElement, {
          scale: 1,
          duration: 0.35,
          ease: 'expo.inOut',
        });
      });
    });
  }, []);

  return (
    <div
      className='secondSection-services-container container'
      ref={servicesContainerRef}
    >
      <div className='secondSection-services-wrapper'>
        {serviceList?.map((service) => (
          <div key={service?.title}>
            {service?.hr && <div className='secondSection-services-line' />}
            <div className='services-service'>
              <div className='services-service-title-wrapper'>
                <div className='services-service-title-location-wrapper'>
                  <div className='services-service-title'>{service?.title}</div>
                  <div className='services-service-title-location-icon-wrapper'>
                    <div className='services-service-title-location-icon'>
                      {service?.icon}
                    </div>
                    <div className='services-service-title-location'>
                      {service?.location}
                    </div>
                  </div>
                  <div className='services-service-price'>{service?.price}</div>
                </div>
                <div className='services-service-button-wrapper'>
                  <div className='services-service-button'>
                    <div className='services-service-button-inner'>
                      <div className='services-service-button-inner-arrow-right-hover'>
                        <h3 className='service-arrow-icon'>
                          <HiOutlineArrowLongRight />
                        </h3>
                      </div>
                      <div className='services-service-button-inner-arrow-right'>
                        <h3 className='service-arrow-icon'>
                          <HiOutlineArrowLongRight />
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='services-service-text'>
                <p>{service?.description}</p>
              </div>
              <div className='services-service-image-wrapper'>
                <div className='services-service-image'>
                  <img src={service?.image} alt='Service' />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// const AreasOfFocus = ({ servicesRef, width }) => {
//   const plantRef = useRef(null);
//   const aofContainerRef = useRef(null);

//   const serviceAreas = [
//     'Depression',
//     'Trauma',
//     'Stress',
//     'Anxiety',
//     'Grief',
//     'Relationship issues',
//     'Parenting issues',
//   ];

//   useGSAP(
//     () => {
//       gsap.registerPlugin(ScrollTrigger);

//       // Ensure that servicesRef.current is not empty and contains valid elements
//       if (servicesRef.current && servicesRef.current.length > 0) {
//         servicesRef.current.forEach((service, index) => {
//           if (service) {
//             const tl = gsap.timeline({
//               scrollTrigger: {
//                 trigger: service,
//                 start: width <= 576 ? 'top center' : 'top center+=20%',
//                 end: width <= 576 ? 'bottom' : 'bottom center+=20%',
//                 scrub: true,
//               },
//             });

//             tl.to(service, { color: 'black', duration: 0.3 }).to(service, {
//               color: 'lightgray',
//               duration: 0.3,
//             });
//           }
//         });
//       }
//     },
//     { scope: aofContainerRef }
//   );

//   // useGSAP(
//   //   () => {
//   //     gsap.registerPlugin(ScrollTrigger);
//   //     ScrollTrigger.create({
//   //       trigger: plantRef.current,
//   //       start: 'top center',
//   //       end: 'bottom',
//   //       pin: true,
//   //       scrub: true,
//   //       markers: true,
//   //     });
//   //   },
//   //   { scope: aofContainerRef }
//   // );

//   return (
//     <div className='aof-container container' ref={aofContainerRef}>
//       <div className='aof-header'>
//         <hr className='aof-hr' />
//         {/* <div className='aof-line desktopSmDisplayNone' /> */}
//         <motion.div
//           className='aof-heading'
//           variants={fadeInUpDelay}
//           initial='initial'
//           whileInView='whileInView'
//           viewport={{ once: true }}
//         >
//           Areas of focus
//         </motion.div>
//       </div>

//       <motion.div
//         className='services-aof-wrapper'
//         variants={fadeInUpDelay}
//         initial='initial'
//         whileInView='whileInView'
//         viewport={{ once: true }}
//       >
//         {serviceAreas.map((serviceArea, index) => (
//           <div
//             className='services-aof-area'
//             ref={(el) => (servicesRef.current[index] = el)}
//             key={index}
//           >
//             <h2>{serviceArea}</h2>
//           </div>
//         ))}
//       </motion.div>

//       <div className='aof-plant-video'>
//         <video
//           src={plant}
//           type='video/mp4'
//           autoPlay
//           muted
//           playsInline
//           preload='metadata'
//           ref={plantRef}
//         />
//       </div>
//     </div>
//   );
// };

const ServiceArea = ({ service, setSelectedArea, id }) => {
  const container = useRef(null);
  const { title, speed } = service;

  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start end', `${45 / speed}vw end`],
  });

  const clipProgress = useTransform(scrollYProgress, [0, 1], [100, 0]);
  const clip = useMotionTemplate`inset(0 ${clipProgress}% 0 0)`;

  return (
    <div
      className='services-aof-text'
      ref={container}
      onMouseOver={() => setSelectedArea(id)}
      onMouseLeave={() => setSelectedArea(null)}
    >
      <motion.p style={{ clipPath: clip }}>{title}</motion.p>
      <p className='second-p'>{title}</p>
    </div>
  );
};

const ServiceDescription = ({ service, selectedArea, setSelectedArea, id }) => {
  const container = useRef(null);
  const { title, description } = service;

  const crop = (string, maxLength) => {
    return string.substring(0, maxLength);
  };

  return (
    <div
      className='services-aof-description'
      ref={container}
      style={{
        clipPath: selectedArea === id ? 'inset(0 0 0)' : 'inset(50% 0 50%',
      }}
      onMouseOver={() => setSelectedArea(id)}
      onMouseLeave={() => setSelectedArea(null)}
    >
      <p className='title'>{crop(title, 15)}</p>
      <p className='description'>{description}</p>
    </div>
  );
};

const AreasOfFocus = () => {
  const aofContainer = useRef(null);

  const serviceAreas = [
    {
      id: 1,
      title: 'Depression',
      description:
        'A mental health condition characterized by persistent feelings of sadness, hopelessness, and a lack of interest or pleasure in activities.',
      speed: 0.5,
    },
    {
      id: 2,
      title: 'Trauma',
      description:
        'A deeply distressing or disturbing experience that can have long-lasting psychological effects.',
      speed: 0.5,
    },
    {
      id: 3,
      title: 'Stress',
      description:
        'A physical and emotional response to external pressures or demands that can affect well-being.',
      speed: 0.67,
    },
    {
      id: 4,
      title: 'Anxiety',
      description:
        'A feeling of worry, nervousness, or unease about an imminent event or uncertain outcome.',
      speed: 0.67,
    },
    {
      id: 5,
      title: 'Grief',
      description:
        'The intense sorrow and emotional suffering experienced after the loss of a loved one or significant life change.',
      speed: 0.8,
    },
    {
      id: 6,
      title: 'Relationship issues',
      description:
        'Conflicts or challenges in personal connections that can affect emotional health and interpersonal dynamics.',
      speed: 0.8,
    },
    {
      id: 7,
      title: 'Parenting issues',
      description:
        'Difficulties or challenges faced in raising and nurturing children.',
      speed: 0.8,
    },
  ];

  const [selectedArea, setSelectedArea] = useState(null);

  return (
    <div className='aof-container container' ref={aofContainer}>
      <div className='aof-header'>
        <hr className='aof-hr' />
        <motion.div
          className='aof-heading'
          variants={fadeInUpDelay}
          initial='initial'
          whileInView='whileInView'
          viewport={{ once: true }}
        >
          Areas of focus
        </motion.div>
      </div>
      <div className='services-aof-title'>
        <motion.div className='services-aof-wrapper'>
          {serviceAreas.map((service) => (
            <React.Fragment key={service?.id}>
              <ServiceArea
                service={service}
                setSelectedArea={setSelectedArea}
                id={service?.id}
              />
              <ServiceDescription
                service={service}
                selectedArea={selectedArea}
                setSelectedArea={setSelectedArea}
                id={service?.id}
              />
            </React.Fragment>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

const SecondSection = ({ sectionWidth }) => {
  const { height, width } = useWindowDimensions();

  const secondSec = useRef(null);
  const bottomHeadingRef = useRef(null);
  const servicesRef = useRef([]);
  const treeContainer = useRef(null);
  const treeHeading = useRef(null);
  const treeDesc = useRef(null);
  const treeImg = useRef(null);
  const treeImg2 = useRef(null);

  useGSAP(
    () => {
      gsap.registerPlugin(ScrollTrigger);

      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: document.documentElement,
          start: height >= 960 ? 'top+=11200px' : 'top+=10300px',
          end: '+=100px',
          scrub: true,
        },
      });

      timeline.to(secondSec.current, { width: '98%' });
    },
    { dependencies: [height], scope: secondSec }
  );

  useGSAP(
    () => {
      gsap.registerPlugin(ScrollTrigger);

      // GSAP animation for 'My Services' text parallax effect
      gsap.to(bottomHeadingRef.current, {
        scrollTrigger: {
          trigger: '.secondSection-services-wrapper',
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
        },
        y: -80,
      });
    },
    { scope: secondSec }
  );

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to('.main-tree-content', {
      scrollTrigger: {
        trigger: 'body',
        scrub: 0.5,
        start: 'top top',
        end: window.innerHeight * 0.4,
      },
      scale: 1,
    });

    gsap.to('.fillers path', {
      scrollTrigger: {
        trigger: '.main-tree-content',
        scrub: 0.5,
        ease: 'power4.in',
        start: 'top',
        end: 'bottom+=1000%',
      },
      strokeDashoffset: 0,
    });
  }, []);

  useGSAP(
    () => {
      gsap.registerPlugin(ScrollTrigger);

      const context = gsap.context(() => {
        const tl = gsap.timeline({
          scrollTrigger: {
            target: treeContainer.current,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true,
          },
        });

        tl.to(treeImg.current, { y: -200 }, 0);
        tl.to(treeImg2.current, { y: -200 }, 0);
        // tl.to(treeHeading.current, { y: -250 }, 0);
        // tl.to(treeDesc.current, { y: -250 }, 0);
      });

      return () => context.revert();
    },
    { scope: secondSec }
  );

  return (
    <motion.div
      className='secondSection-container'
      style={{ width: sectionWidth }}
      variants={fadeInVideo}
      initial='initial'
      animate='animate'
      ref={secondSec}
    >
      <div className='secondSection-wrapper'>
        <div className='main-tree-content'>
          <svg
            viewBox='0 0 1440 4096'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g className='backers'>
              <path
                d='M-3317 96H387c276.142 0 500 223.858 500 500v1064.51c0 99.41-80.589 180-180 180H434.99c-99.412 0-180.001 80.58-180.001 180V4248'
                stroke='red'
                strokeWidth='100'
                strokeLinecap='round'
              />
              <path
                d='M4379 804H1387c-276.14 0-499.997 223.86-499.997 500v356.51c0 99.41-80.589 180-180 180H434.991c-99.411 0-180 80.59-180 180V4248'
                stroke='red'
                strokeWidth='100'
                strokeLinecap='round'
              />
              <path
                d='M4423 96H1387.02c-276.14 0-500.001 223.858-500.001 500.001V1660.51c0 99.41-80.589 180-180 180H434.995c-99.411 0-180 80.59-180 180l.001 2227.49'
                stroke='red'
                strokeWidth='100'
                strokeLinecap='round'
              />
            </g>
            <g className='fillers'>
              <path
                d='M-3317 96H387c276.142 0 500 223.858 500 500v1064.51c0 99.41-80.589 180-180 180H434.99c-99.412 0-180.001 80.58-180.001 180V4248'
                stroke='red'
                strokeWidth='100'
                strokeLinecap='round'
              />
              <path
                d='M4379 804H1387c-276.14 0-499.997 223.86-499.997 500v356.51c0 99.41-80.589 180-180 180H434.991c-99.411 0-180 80.59-180 180V4248'
                stroke='red'
                strokeWidth='100'
                strokeLinecap='round'
              />
              <path
                d='M4423 96H1387.02c-276.14 0-500.001 223.858-500.001 500.001V1660.51c0 99.41-80.589 180-180 180H434.995c-99.411 0-180 80.59-180 180l.001 2227.49'
                stroke='red'
                strokeWidth='100'
                strokeLinecap='round'
              />
            </g>
          </svg>
          <section className='main-tree-section'>
            <div className='main-tree-desc-wrapper container'>
              <motion.div
                className='main-tree-desc-heading'
                variants={fadeInUpDelay}
                initial='initial'
                whileInView='whileInView'
                viewport={{ once: true }}
              >
                <h1>
                  <span>( a. )</span> What is therapy?
                </h1>
              </motion.div>
              <motion.div
                className='main-tree-desc'
                variants={fadeInUpDelay}
                initial='initial'
                whileInView='whileInView'
                viewport={{ once: true }}
              >
                <p>
                  Therapy is a collaborative process that facilitates a process
                  of change. This is done through exploring issues, beliefs, and
                  developing new skills to change unhelpful behaviour. Using
                  research-based orientations and interventions, you and I will
                  work together to help you discover your own capabilities and
                  empower you to facilitate positive change. May Wellness is a
                  professional psychotherapy practice that serves adolescents,
                  and adults seeking psychotherapeutic services.
                </p>
              </motion.div>
            </div>
          </section>
          <section className='main-tree-section'>
            <div
              className='main-tree-desc-wrapper container'
              ref={treeContainer}
            >
              <div className='main-tree-desc-bgImg' ref={treeImg}>
                {/* <Reveal delayTime={0.7} delayTimeOpacity={0.65}> */}
                <img src={chairs} alt='Therapy' loading='lazy' />
                {/* </Reveal> */}
              </div>
              <motion.div
                className='main-tree-desc-heading'
                variants={fadeInUpDelay}
                initial='initial'
                whileInView='whileInView'
                viewport={{ once: true }}
                ref={treeHeading}
              >
                <h1>
                  <span>( b. )</span> How can I help?
                </h1>
              </motion.div>
              <motion.div
                className='main-tree-desc'
                variants={fadeInUpDelay}
                initial='initial'
                whileInView='whileInView'
                viewport={{ once: true }}
                ref={treeDesc}
              >
                <p>
                  I aim to provide a safe non judgmental environment that allows
                  one to explore emotions in a constructive way and make a
                  positive change and attain personal goals. I focus on managing
                  stress, reducing symptoms of anxiety, processing trauma and
                  emotional pain. I tailor each session to you specifically so
                  that I can better support and guide you.
                </p>
              </motion.div>
            </div>
          </section>
          <section className='main-tree-section'>
            <div className='main-tree-desc-wrapper-bottom-container'>
              <div className='main-tree-desc-wrapper-bottom'>
                <motion.div
                  className='main-tree-desc-heading'
                  variants={fadeInUpDelay}
                  initial='initial'
                  whileInView='whileInView'
                  viewport={{ once: true }}
                >
                  <h1>
                    <span>( c. )</span> What is my goal?
                  </h1>
                </motion.div>
                <motion.div
                  className='main-tree-desc'
                  variants={fadeInUpDelay}
                  initial='initial'
                  whileInView='whileInView'
                  viewport={{ once: true }}
                >
                  <p>
                    As a therapist my goal is to make sure you are heard and
                    valued. I am passionate about helping people reach their
                    full potential, acknowledging how our mind and body impacts
                    each other. I want to help one feel better both physically
                    and mentally through support and fostering change.
                  </p>
                </motion.div>
              </div>
            </div>
          </section>
          <section className='main-tree-section'>
            <div className='main-tree-desc-wrapper-bottom-container'>
              <div className='main-tree-desc-wrapper-bottom'>
                <div className='main-tree-desc-bgImg2' ref={treeImg2}>
                  <img src={heads} alt='Therapy' loading='lazy' />
                </div>
                <motion.div
                  className='main-tree-desc-heading'
                  variants={fadeInUpDelay}
                  initial='initial'
                  whileInView='whileInView'
                  viewport={{ once: true }}
                >
                  <h1>
                    <span>( d. )</span> Expert Counselling
                  </h1>
                </motion.div>
                <motion.div
                  className='main-tree-desc'
                  variants={fadeInUpDelay}
                  initial='initial'
                  whileInView='whileInView'
                  viewport={{ once: true }}
                >
                  <p>
                    At May Wellness, we offer professional and confidential
                    online counselling services to help you navigate life's
                    challenges. Our sessions are conducted virtually, providing
                    you with the flexibility to access supportive care from the
                    comfort and privacy of your own home. We are dedicated to
                    working with you to build a treatment plan that is
                    customized for your unique needs and therapeutic goals.
                  </p>
                </motion.div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <AreasOfFocus servicesRef={servicesRef} width={width} />

      <TestimonialsCarousel bottomHeadingRef={bottomHeadingRef} width={width} />

      <Services />

      {/* <Quote /> */}

      <FaqComponent />
    </motion.div>
  );
};

const HomeHero = ({ scrollOpacity }) => {
  const homeHeadingRef = useRef(null);
  const homeHeroContainerRef = useRef(null);
  const { width, height } = useWindowDimensions();

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const homeHeading = homeHeadingRef.current;
    const splitText = new SplitType(homeHeading, { type: 'words' });

    gsap.from(splitText.words, {
      opacity: 0,
      y: 20,
      stagger: 0.1,
      ease: 'power2',
      scrollTrigger: {
        trigger: width <= 576 ? '.home-video' : homeHeading,
        start: width <= 576 ? 'top' : 'top 100%',
        end: 'bottom 60%',
        scrub: true,
      },
    });
  }, []);

  return (
    <>
      <motion.div
        className='home-video'
        variants={fadeInVideo}
        initial='initial'
        animate='animate'
        ref={homeHeroContainerRef}
      >
        <video
          src={forestBg}
          type='video/mp4'
          autoPlay
          loop
          muted
          playsInline
          preload='metadata'
        />
        <div className='video-overlay' style={{ opacity: scrollOpacity }}></div>
      </motion.div>
      <div className='home-heading-wrapper'>
        <motion.div
          className='home-heading container'
          variants={fadeInVideo}
          initial='initial'
          animate='animate'
        >
          <h1 ref={homeHeadingRef}>
            Empower your
            <br />
            <span>mental</span> health
          </h1>
        </motion.div>
      </div>
    </>
  );
};

const Home = () => {
  const [footerOnTop, setFooterOnTop] = useState(false);
  const [scrollOpacity, setScrollOpacity] = useState(0);
  const [secondSectionWidth, setSecondSectionWidth] = useState('100%');
  const [parallaxOffsetTop, setParallaxOffsetTop] = useState(0);
  const [parallaxOffsetBottom, setParallaxOffsetBottom] = useState(0);

  // Scroll to top of page on render
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const height = window.innerHeight;
      const threshold = 150; // Pixels scrolled before the effect starts

      const newOpacity = Math.max(
        0,
        Math.min(0.5, (scrollY - threshold) / (height - threshold))
      );

      setScrollOpacity(newOpacity);
    };

    // Add event listener
    window.addEventListener('scroll', handleScroll);

    // Refresh ScrollTriggers after everything is loaded
    ScrollTrigger.refresh();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Footer z-index
  useEffect(() => {
    const handleScrollFooter = () => {
      // Determine the scroll threshold you want to use.
      // This could be when the video is out of view or another specific point.
      const scrollThreshold = window.innerHeight / 0.5; // For example, when the entire viewport height is scrolled

      if (window.scrollY > scrollThreshold) {
        setFooterOnTop(true);
      } else {
        setFooterOnTop(false);
      }
    };

    window.addEventListener('scroll', handleScrollFooter);

    return () => {
      window.removeEventListener('scroll', handleScrollFooter);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Wisdomway Wellness</title>
        <meta
          name='description'
          content='Maha Jawed - Professional Therapist Ontario'
        />
        <meta
          name='keywords'
          content='therapy, wisdomway wellness, psychotherapy, therapist, Ontario, Toronto, Canada, Psychology, Psychologist'
        />
      </Helmet>

      <Inner>
        <div className='home-container mainContent' data-scroll-section>
          <HomeHero scrollOpacity={scrollOpacity} />
          <SecondSection sectionWidth={secondSectionWidth} />
          <Footer isOnTop={footerOnTop} />
        </div>
      </Inner>
    </>
  );
};

export default Home;
