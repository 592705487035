import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Navbar from '../../Navbar/Navbar';
import { About } from '../../../pages';
import './styles.scss';

const Inner = ({ children }) => {
  const perspective = {
    initial: {
      y: 0,
    },

    enter: {
      y: 0,
    },

    exit: {
      y: -150,

      opacity: 0.5,

      transition: {
        duration: 1.2,

        ease: [0.76, 0, 0.24, 1],
      },
    },
  };

  const slide = {
    initial: {
      y: '100vh',
    },

    enter: {
      y: '100vh',
    },

    exit: {
      y: 0,

      transition: {
        duration: 1,

        ease: [0.76, 0, 0.24, 1],
      },
    },
  };

  const anim = (variants) => {
    return {
      initial: 'initial',

      animate: 'enter',

      exit: 'exit',

      variants,
    };
  };

  const [showAboutPage, setShowAboutPage] = useState(false);

  return (
    <div className='inner'>
      <motion.div className='slide mobileSmDisplayNone' {...anim(slide)} />
      <motion.div className='page' {...anim(perspective)}>
        <Navbar setShowAboutPage={setShowAboutPage} />
        <AnimatePresence>
          {showAboutPage && (
            <About
              showAboutPage={showAboutPage}
              setShowAboutPage={setShowAboutPage}
            />
          )}
        </AnimatePresence>
        {children}
      </motion.div>
    </div>
  );
};

export default Inner;
