import React from 'react';
import './PerspectiveText.scss';

const PerspectiveText = ({ label }) => {
  return (
    <div className='perspectiveText'>
      <p>{label}</p>
      <p>{label}</p>
    </div>
  );
};

export default PerspectiveText;
