import React, { useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';

const Reveal = ({ children, width, delayTime, delayTimeOpacity }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  const slideControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start('visible');
      slideControls.start('visible');
    }
  }, [isInView]);

  return (
    <div ref={ref} style={{ position: 'relative', width, overflow: 'hidden' }}>
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
          },
          visible: {
            opacity: 1,
          },
        }}
        initial='hidden'
        animate={mainControls}
        transition={{ duration: 0.5, delay: delayTimeOpacity }}
      >
        {children}
      </motion.div>
      {
        <motion.div
          variants={{
            hidden: { left: 0 },
            visible: { left: '100%' },
          }}
          initial='hidden'
          animate={slideControls}
          transition={{ duration: 0.9, ease: 'easeInOut', delay: delayTime }}
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: 'lightGray',
            zIndex: 2,
          }}
        />
      }
    </div>
  );
};

Reveal.defaultProps = {
  width: 'fit-content',
  delayTime: 0.2,
  delayTimeOpacity: 0.25,
};

export default Reveal;
