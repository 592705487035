import { ReactLenis } from '@studio-freight/react-lenis';
import { AnimatePresence } from 'framer-motion';
import { HelmetProvider } from 'react-helmet-async';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Home, Services } from '../pages';

import './App.scss';

function App() {
  const location = useLocation();

  return (
    <HelmetProvider location={location} key='a1'>
      <ReactLenis root options={{ duration: 1.5, smoothTouch: false }}>
        <div className='app'>
          <AnimatePresence mode='wait'>
            <Routes location={location} key={location.pathname}>
              <Route exact path='/' element={<Home />} />
            </Routes>
          </AnimatePresence>
        </div>
      </ReactLenis>
    </HelmetProvider>
  );
}

export default App;
