import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import './About.scss';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type';
import { logo, logo2, profile, profile2 } from '../../assets';
import Reveal from '../../utils/Reveal';
import { expandFromLeft } from '../../components/Transition';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(ScrollTrigger);

const TextRevealComponent = ({ modalRef }) => {
  const sectionsRef = useRef([]);

  useEffect(() => {
    const sections = sectionsRef.current;

    sections.forEach((section) => {
      const char = section.querySelector('.reveal-type');
      const bg = char.dataset.bgColor;
      const fg = char.dataset.fgColor;

      const splitText = new SplitType(char, { types: 'words' });

      gsap.fromTo(
        splitText.words,
        { color: bg },
        {
          color: fg,
          duration: 1.5,
          stagger: 0.07,
          scrollTrigger: {
            trigger: modalRef.current,
            scroller: modalRef.current,
            start: 'top-=5%',
            end: 'bottom-=10%',
            scrub: true,
            // toggleActions: 'restart pause reverse pause',
          },
        }
      );
    });
  }, [modalRef]);

  return (
    <div className='textReveal-container'>
      <section ref={(el) => (sectionsRef.current[0] = el)}>
        <p
          className='reveal-type textReveal-content'
          data-bg-color='gray'
          data-fg-color='white'
        >
          Hi my name is Maha Jawed and I am a Registered Psychotherapist
          (Qualifying). I follow a client centered approach and believe that you
          are an expert on your own life therefore it would be a privilege for
          me to help you navigate your therapeutic journey.
          <br />
          <br />I have done a Masters in Counseling Psychology at Yorkville
          University and an Honors in Bachelors of Child Development and have
          worked in various settings with children, individuals and families all
          over the world.
        </p>
      </section>
    </div>
  );
};

const About = ({ showAboutPage, setShowAboutPage }) => {
  const backdrop = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const modal = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const modalRef = useRef(null);
  const imageContainer = useRef(null);

  // useEffect(() => {
  //   // Capture the current scroll position
  //   const scrollX = window.pageXOffset || document.documentElement.scrollLeft;
  //   const scrollY = window.pageYOffset || document.documentElement.scrollTop;

  //   const originalHtmlStyle = document.documentElement.style.overflow;
  //   document.documentElement.style.overflow = 'hidden';

  //   // Restore the scroll position and overflow styles when the component unmounts
  //   return () => {
  //     document.documentElement.style.overflow = originalHtmlStyle;

  //     // Restore the scroll position
  //     window.scrollTo(scrollX, scrollY);
  //   };
  // }, []);

  useGSAP(
    () => {
      gsap.registerPlugin(ScrollTrigger);
      ScrollTrigger.create({
        trigger: imageContainer.current,
        scroller: modalRef.current,
        start: '-=200px',
        end: 'bottom-=580px',
        pin: true,
      });
    },
    { scope: modalRef }
  );

  return (
    <>
      {showAboutPage && (
        <motion.div
          className='about-container'
          variants={backdrop}
          initial='hidden'
          animate='visible'
          exit='exit'
          key='aboutModal'
          ref={modalRef}
          data-lenis-prevent
        >
          <motion.div
            className='about-wrapper container'
            variants={modal}
            initial='hidden'
            animate='visible'
            exit='exit'
            data-scroll-section
          >
            <div className='about-nav'>
              <div className='about-logo'>
                <img src={logo} alt='logo' loading='lazy' />
              </div>
              <button onClick={() => setShowAboutPage(false)}>Close</button>
            </div>

            <div
              className='about-profile mobileSmDisplayNone'
              ref={imageContainer}
            >
              <Reveal>
                <img src={profile2} alt='Profile' loading='lazy' />
              </Reveal>
            </div>

            <div className='about-content'>
              <TextRevealComponent modalRef={modalRef} />
            </div>

            <div className='about-profile-mobile desktopSmDisplayNone'>
              <Reveal>
                <img src={profile2} alt='Profile' loading='lazy' />
              </Reveal>
            </div>

            <div className='about-description'>
              <div className='about-member'>
                <div className='h-line' />
                <h3>Member of</h3>
                <div className='about-member-list'>
                  <p>
                    College of Registered Psychotherapists of Ontario (CRPO)
                  </p>
                  <p>American Counselling Association (ACA)</p>
                  <p>American College Counselling Association (ACCA)</p>
                  <p>
                    International Association of Resilience and Trauma
                    Counseling (IARTC)
                  </p>
                </div>
              </div>

              <div className='about-focus'>
                <div className='h-line' />
                <h3>Areas of focus</h3>
                <div className='about-focus-list'>
                  <p>Depression</p>
                  <p>Trauma</p>
                  <p>Stress</p>
                  <p>Anxiety</p>
                  <p>Grief</p>
                  <p>Relationship issues</p>
                  <p>Parenting issues</p>
                </div>
              </div>
            </div>

            <div className='about-footer'>
              <div className='about-footer-left'>
                <div className='about-footer-logo'>
                  <img src={logo} alt='Logo' loading='lazy' />
                </div>
              </div>
              <div className='about-footer-right mobileSmDisplayNone'>
                <div className='about-footer-right-email'>
                  <h3>maha@wisdomwaywellness.com</h3>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </>
  );
};

export default About;
