import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { fadeInUp, fadeInVideo } from '../Transition';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useNavigate } from 'react-router-dom';
import PerspectiveText from '../PerspectiveText/PerspectiveText';
import { HiArrowLongLeft } from 'react-icons/hi2';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import emailjs from '@emailjs/browser';
import { useGSAP } from '@gsap/react';
import './Navbar.scss';

const ContactForm = ({ isActive, setOpenForm, formType }) => {
  const nameRegex = /^[a-zA-Z]+(?:[-' ][a-zA-Z]+)*$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^(\d{3}-\d{3}-\d{4}|\d{10})$/;

  const [fName, setFName] = useState('');
  const [lName, setLName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
  const [fieldError, setFieldError] = useState('');

  const formRef = useRef();
  const arrow = useRef();
  const hoverArrow = useRef();
  const contactBtnRef = useRef();

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    contactBtnRef.current.addEventListener('mouseenter', () => {
      gsap.to(arrow.current, { x: -30, opacity: 0, duration: 0.1 }); // Move left and fade out
      gsap.fromTo(
        hoverArrow.current,
        { x: 30, opacity: 0 },
        { x: 0, opacity: 1, duration: 0.1 }
      );
    });

    contactBtnRef.current.addEventListener('mouseleave', () => {
      gsap.to(arrow.current, { x: 0, opacity: 1, duration: 0.1 }); // Return to center and fade in
      gsap.to(hoverArrow.current, { x: 30, opacity: 0, duration: 0.1 }); // Move left and fade out
    });
  }, []);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    setFieldError('');
    setErrorMsg('');
    setIsSubmitting(true);

    if (fName === '' || lName === '' || email === '' || phoneNum === '') {
      setIsSubmitting(false);
      setErrorMsg('Please fill out all required fields');
      if (fName === '') setFieldError('fName');
      else if (lName === '') setFieldError('lName');
      else if (email === '') setFieldError('email');
      else if (phoneNum === '') setFieldError('phoneNum');
      return;
    }

    if (!nameRegex.test(fName)) {
      setIsSubmitting(false);
      setErrorMsg('Invalid first name');
      setFieldError('fName');
      return;
    }
    if (!nameRegex.test(lName)) {
      setIsSubmitting(false);
      setErrorMsg('Invalid last name');
      setFieldError('lName');
      return;
    }
    if (!emailRegex.test(email)) {
      setIsSubmitting(false);
      setErrorMsg('Invalid email address');
      setFieldError('email');
      return;
    }
    if (!phoneRegex.test(phoneNum)) {
      setIsSubmitting(false);
      setErrorMsg('Invalid phone number');
      setFieldError('phoneNum');
      return;
    }

    emailjs
      .sendForm(
        'service_pgv85vo',
        'template_ybe1bbc',
        formRef.current, // Directly passing the form reference
        'hKGddlm6XmEF9Diq9'
      )
      .then(
        (result) => {
          setIsSubmitting(false);
          setSubmissionSuccessful(true);
        },
        (error) => {
          console.log(error.text);
          setIsSubmitting(false);
          setSubmissionSuccessful(true);
        }
      );
  };

  if (submissionSuccessful) {
    return <div className='email-sent-message'>Email Sent Successfully!</div>;
  }

  return (
    <motion.div
      className='contactForm-container'
      animate={{ opacity: isActive ? 1 : 0 }}
      initial={{
        opacity: 0,
      }}
      exit={{
        opacity: 0,
      }}
    >
      <div
        className='contactForm-backBtn'
        onClick={() => setOpenForm(false)}
        ref={contactBtnRef}
      >
        <div className='contactForm-backBtn-inner'>
          <div
            className='contactForm-backBtn-inner-arrow-right-hover'
            ref={hoverArrow}
          >
            <h3 className='contactForm-backBtn-icon'>
              <HiArrowLongLeft />
            </h3>
          </div>
          <div className='contactForm-backBtn-inner-arrow-right' ref={arrow}>
            <h3 className='contactForm-backBtn-icon'>
              <HiArrowLongLeft />
            </h3>
          </div>
        </div>

        {/* <div className='contactForm-backBtn-icon'>
          <HiArrowLongLeft />
        </div> */}
        <div className='contactForm-backBtn-btn-text'>
          <h3>Back</h3>
          <div className='contactForm-error'>{errorMsg}</div>
        </div>
      </div>
      <form className='contactForm' ref={formRef} onSubmit={handleSubmitForm}>
        <div className='contactForm-name'>
          <div className='contactForm-name-fName'>
            <input
              className={
                fieldError === 'fName' ? 'input-error' : 'contactForm-input'
              }
              type='text'
              placeholder='First Name'
              name='user_fname'
              value={fName}
              onChange={(e) => setFName(e.target.value)}
            />
          </div>
          <div className='contactForm-name-lName'>
            <input
              className={
                fieldError === 'lName' ? 'input-error' : 'contactForm-input'
              }
              type='text'
              placeholder='Last Name'
              name='user_lname'
              value={lName}
              onChange={(e) => setLName(e.target.value)}
            />
          </div>
        </div>
        <div className='contactForm-email'>
          <input
            className={
              fieldError === 'email' ? 'input-error' : 'contactForm-input'
            }
            type='email'
            placeholder='Email'
            name='user_email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className='contactForm-phone'>
          <input
            className={
              fieldError === 'phoneNum' ? 'input-error' : 'contactForm-input'
            }
            type='tel'
            placeholder='Phone Number'
            name='user_phone'
            value={phoneNum}
            onChange={(e) => setPhoneNum(e.target.value)}
          />
        </div>
        <div className='contactForm-details'>
          <textarea
            name='user_details'
            id='contactForm'
            cols='30'
            rows='10'
            placeholder='Additional details (optional)'
            value={additionalDetails}
            onChange={(e) => setAdditionalDetails(e.target.value)}
          ></textarea>
        </div>
        <div className='contactForm-type'>
          <select name='form_type' id='formType'>
            <option value={formType}>{formType}</option>
          </select>
        </div>
        <div className='contactForm-saveBtn'>
          <button
            className={
              isSubmitting ? 'contactForm-btn-loading' : 'contactForm-btn'
            }
          >
            <span className='label'>Submit</span>
          </button>
        </div>
      </form>
    </motion.div>
  );
};

const ContactMenu = ({ isActive, openForm, setOpenForm, width, height }) => {
  const [formType, setFormType] = useState('');

  const calculateRightValueClosed = (currentWidth) => {
    if (currentWidth >= 3460) {
      return '480px';
    } else if (currentWidth >= 3440) {
      return '470px';
    } else if (currentWidth >= 3420) {
      return '460px';
    } else if (currentWidth >= 3400) {
      return '450px';
    } else if (currentWidth >= 3380) {
      return '440px';
    } else if (currentWidth >= 3360) {
      return '430px';
    } else if (currentWidth >= 3340) {
      return '420px';
    } else if (currentWidth >= 3320) {
      return '410px';
    } else if (currentWidth >= 3300) {
      return '400px';
    } else if (currentWidth >= 3280) {
      return '390px';
    } else if (currentWidth >= 3260) {
      return '380px';
    } else if (currentWidth >= 3240) {
      return '370px';
    } else if (currentWidth >= 3220) {
      return '360px';
    } else if (currentWidth >= 3200) {
      return '350px';
    } else if (currentWidth >= 3180) {
      return '340px';
    } else if (currentWidth >= 3160) {
      return '330px';
    } else if (currentWidth >= 3140) {
      return '320px';
    } else if (currentWidth >= 3120) {
      return '310px';
    } else if (currentWidth >= 3100) {
      return '300px';
    } else if (currentWidth >= 3080) {
      return '290px';
    } else if (currentWidth >= 3060) {
      return '280px';
    } else if (currentWidth >= 3040) {
      return '270px';
    } else if (currentWidth >= 3020) {
      return '260px';
    } else if (currentWidth >= 3000) {
      return '250px';
    } else if (currentWidth >= 2980) {
      return '240px';
    } else if (currentWidth >= 2960) {
      return '230px';
    } else if (currentWidth >= 2940) {
      return '220px';
    } else if (currentWidth >= 2920) {
      return '210px';
    } else if (currentWidth >= 2900) {
      return '200px';
    } else if (currentWidth >= 2880) {
      return '190px';
    } else if (currentWidth >= 2860) {
      return '180px';
    } else if (currentWidth >= 2840) {
      return '170px';
    } else if (currentWidth >= 2820) {
      return '160px';
    } else if (currentWidth >= 2800) {
      return '150px';
    } else if (currentWidth >= 2780) {
      return '140px';
    } else if (currentWidth >= 2760) {
      return '130px';
    } else if (currentWidth >= 2740) {
      return '120px';
    } else if (currentWidth >= 2720) {
      return '110px';
    } else if (currentWidth >= 2700) {
      return '100px';
    } else if (currentWidth >= 2680) {
      return '90px';
    } else if (currentWidth >= 2660) {
      return '80px';
    } else if (currentWidth >= 2640) {
      return '70px';
    } else if (currentWidth >= 2600) {
      return '55px';
    } else if (currentWidth >= 2580) {
      return '50px';
    } else if (currentWidth >= 2570) {
      return '35px';
    } else if (currentWidth >= 2500) {
      return '28px';
    } else if (currentWidth >= 568) {
      return '28px';
    } else if (currentWidth >= 168) {
      return '8px';
    } else {
      return '30px';
    }
  };

  const calculateRightValueOpen = (currentWidth) => {
    if (currentWidth >= 3460) {
      return '470px';
    } else if (currentWidth >= 3440) {
      return '460px';
    } else if (currentWidth >= 3420) {
      return '450px';
    } else if (currentWidth >= 3400) {
      return '440px';
    } else if (currentWidth >= 3380) {
      return '430px';
    } else if (currentWidth >= 3360) {
      return '420px';
    } else if (currentWidth >= 3340) {
      return '410px';
    } else if (currentWidth >= 3320) {
      return '400px';
    } else if (currentWidth >= 3300) {
      return '390px';
    } else if (currentWidth >= 3280) {
      return '380px';
    } else if (currentWidth >= 3260) {
      return '370px';
    } else if (currentWidth >= 3240) {
      return '360px';
    } else if (currentWidth >= 3220) {
      return '350px';
    } else if (currentWidth >= 3200) {
      return '340px';
    } else if (currentWidth >= 3180) {
      return '330px';
    } else if (currentWidth >= 3160) {
      return '320px';
    } else if (currentWidth >= 3140) {
      return '310px';
    } else if (currentWidth >= 3120) {
      return '300px';
    } else if (currentWidth >= 3100) {
      return '290px';
    } else if (currentWidth >= 3080) {
      return '280px';
    } else if (currentWidth >= 3060) {
      return '270px';
    } else if (currentWidth >= 3040) {
      return '260px';
    } else if (currentWidth >= 3020) {
      return '250px';
    } else if (currentWidth >= 3000) {
      return '240px';
    } else if (currentWidth >= 2980) {
      return '230px';
    } else if (currentWidth >= 2960) {
      return '220px';
    } else if (currentWidth >= 2940) {
      return '210px';
    } else if (currentWidth >= 2920) {
      return '200px';
    } else if (currentWidth >= 2900) {
      return '190px';
    } else if (currentWidth >= 2880) {
      return '180px';
    } else if (currentWidth >= 2860) {
      return '170px';
    } else if (currentWidth >= 2840) {
      return '160px';
    } else if (currentWidth >= 2820) {
      return '150px';
    } else if (currentWidth >= 2800) {
      return '140px';
    } else if (currentWidth >= 2780) {
      return '130px';
    } else if (currentWidth >= 2760) {
      return '120px';
    } else if (currentWidth >= 2740) {
      return '110px';
    } else if (currentWidth >= 2720) {
      return '100px';
    } else if (currentWidth >= 2700) {
      return '90px';
    } else if (currentWidth >= 2680) {
      return '80px';
    } else if (currentWidth >= 2660) {
      return '70px';
    } else if (currentWidth >= 2640) {
      return '60px';
    } else if (currentWidth >= 2600) {
      return '45px';
    } else if (currentWidth >= 2580) {
      return '40px';
    } else if (currentWidth >= 2570) {
      return '25px';
    } else if (currentWidth >= 2500) {
      return '18px';
    } else if (currentWidth >= 568) {
      return '18px';
    } else if (currentWidth >= 168) {
      return '0.01px';
    } else {
      return '20px';
    }
  };

  const variants = {
    open: {
      width: width <= 568 ? '100%' : 480,
      height: width <= 568 ? '100%' : 800,
      top: width <= 568 ? 0 : '20px',
      right: calculateRightValueOpen(width),
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
      borderRadius: width <= 568 ? 0 : '25px',
      transition: {
        duration: 0.75,
        ease: [0.76, 0, 0.24, 1],
      },
    },
    closed: {
      width: 100,
      height: 40,
      top: width <= 568 ? '20px' : '29.5px',
      right: calculateRightValueClosed(width),
      borderRadius: '25px',
      transition: {
        duration: 0.75,
        ease: [0.76, 0, 0.24, 1],
      },
    },
  };

  const handleOpenForm = (type) => {
    setFormType(type);
    setOpenForm(true);
  };

  return (
    <motion.div
      className='contact-menu'
      variants={variants}
      animate={isActive ? 'open' : 'closed'}
      initial={{
        opacity: 0,
      }}
      exit={{
        opacity: 0,
      }}
    >
      <motion.div
        className='contact-menu-wrapper'
        animate={{ display: isActive ? 'flex' : 'none' }}
        transition={{
          delay: 0.2,
        }}
        exit={{
          opacity: 0,
        }}
      >
        <motion.div
          className='contact-menu-options-header'
          animate={{ y: isActive ? 0 : 60, opacity: isActive ? 1 : 0 }}
          transition={{
            delay: 0.7,
          }}
          initial={{ y: 60 }}
          exit={{
            opacity: 0,
          }}
        >
          <h2>
            {!openForm
              ? 'What type of therapy are you looking for?'
              : 'Enter your contact details'}
          </h2>
        </motion.div>
        {!openForm ? (
          <div className='contact-menu-options'>
            <motion.div
              className='contact-menu-option'
              animate={{ y: isActive ? 0 : 60, opacity: isActive ? 1 : 0 }}
              transition={{
                delay: 0.8,
              }}
              exit={{
                opacity: 0,
              }}
              onClick={() => handleOpenForm('Free Consultation')}
            >
              <h3>Free Consultation</h3>
            </motion.div>
            <motion.div
              className='contact-menu-option'
              animate={{ y: isActive ? 0 : 60, opacity: isActive ? 1 : 0 }}
              transition={{
                delay: 0.9,
              }}
              exit={{
                opacity: 0,
              }}
              onClick={() => handleOpenForm('Individual Counselling')}
            >
              <h3>Individual Counselling</h3>
            </motion.div>
            <motion.div
              className='contact-menu-option'
              animate={{ y: isActive ? 0 : 60, opacity: isActive ? 1 : 0 }}
              transition={{
                delay: 1,
              }}
              exit={{
                opacity: 0,
              }}
              onClick={() => handleOpenForm('Couples Therapy')}
            >
              <h3>Couples Therapy</h3>
            </motion.div>
            <motion.div
              className='contact-menu-option'
              animate={{ y: isActive ? 0 : 60, opacity: isActive ? 1 : 0 }}
              transition={{
                delay: 1.1,
              }}
              exit={{
                opacity: 0,
              }}
              onClick={() => handleOpenForm('Other')}
            >
              <h3>Other</h3>
            </motion.div>
          </div>
        ) : (
          <ContactForm
            isActive={isActive}
            setOpenForm={setOpenForm}
            formType={formType}
          />
        )}
      </motion.div>
    </motion.div>
  );
};

const ContactButton = ({ isActive, setIsActive, setOpenForm }) => {
  const toggleContact = () => {
    setIsActive(!isActive);
    setTimeout(() => setOpenForm(false), 500);
  };

  return (
    <div className='navbar-contact ' onClick={toggleContact}>
      <motion.div
        className='navbar-contact-slider'
        animate={{
          top: isActive ? '-100%' : '0',
        }}
        transition={{
          duration: 0.5,
          ease: [0.76, 0, 0.24, 1],
        }}
      >
        <div className='navbar-contact-el'>
          <PerspectiveText label='Contact' />
        </div>
        <div className='navbar-contact-el'>
          <PerspectiveText label='Close' />
        </div>
      </motion.div>
    </div>
  );
};

const Navbar = ({ setShowAboutPage }) => {
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();

  const [isActive, setIsActive] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  const [animate, setAnimate] = useState(false);

  const logoRef = useRef(null);

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: document.documentElement,
        start: 'top',
        end: '+=300px',
        scrub: true,
      },
    });

    timeline.to(logoRef.current, { width: width <= 568 ? '140px' : '200px' });
  }, []);

  useEffect(() => {
    if (logoRef.current) {
      logoRef.current.classList.add('active');
    }
  }, []);

  return (
    <>
      <nav className='navbar-container container' key='nav1'>
        <div className='navbar'>
          <div
            className='navbar-logo'
            variants={fadeInUp}
            initial='initial'
            animate='animate'
            onClick={() => setAnimate(true)}
          >
            {/* <img src={logo} alt='logo' ref={logoRef} loading='lazy' /> */}

            <svg
              className={animate ? 'active' : ''}
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
              x='0px'
              y='0px'
              width='100%'
              height='100%'
              viewBox='0 0 1189.534 301.485'
              enableBackground='new 0 0 1189.534 301.485'
              ref={logoRef}
            >
              <g id='Layer_1'>
                <path
                  stroke='#ffffff'
                  strokeWidth='4'
                  strokeMiterlimit='10'
                  d='M269.523,247.408c-0.092,0.047-0.186,0.088-0.278,0.131
		l-0.007-0.014c-1.685,0.834-3.442,1.645-5.212,2.414c-0.088,0.039-0.175,0.08-0.262,0.117c-0.862,0.359-1.725,0.721-2.603,1.063
		c-12.705,5.002-26.119,7.539-39.866,7.539c-19.35,0-38.328-5.135-54.916-14.852c-5.645-15.205-3.556-30.135-0.789-40.02
		c0.863-3.074,2.866-5.613,5.642-7.184c0.124-0.045,0.237-0.107,0.339-0.186c2.788-1.463,5.952-1.762,8.933-0.826
		c9.605,3.021,19.75,3.504,26.563,3.377c2.914-0.051,5.629-1.52,7.265-3.926c1.632-2.4,1.984-5.332,0.967-8.043
		c-3.947-10.516-0.952-12.568,3.193-15.414c1.102-0.756,2.239-1.535,3.347-2.52c2.853-2.539,4.005-4.934,3.424-7.115
		c-0.644-2.418-3.265-3.922-6.058-4.854c6.708-1.814,6.809-2.119,7.031-2.801c0.175-0.525,0.263-0.789-3.13-8.787
		c-0.018-0.04-0.038-0.079-0.059-0.117c-0.514-0.927-0.277-2.081,0.567-2.748c2.693-2.144,5.519-5.069,7.414-7.148
		c1.649-1.81,1.664-4.555,0.037-6.387l-15.502-17.476c-1.914-2.155-2.72-5.151-2.157-8.019c6.178-31.498-3.009-50.207-12.528-60.663
		c6.684-1.271,13.541-1.915,20.416-1.915c16.926,0,33.152,3.784,48.228,11.248c0.553,0.273,1.223,0.105,1.58-0.399
		c0.356-0.504,0.292-1.192-0.15-1.623c-26.043-25.277-60.37-39.2-96.656-39.2c-19.871,0-39.056,4.11-57.025,12.216
		c-0.025,0.012-0.049,0.024-0.075,0.037c-11.398,5.15-22.027,11.819-31.657,19.841c-0.622-0.384-1.351-0.61-2.136-0.61
		c-2.249,0-4.073,1.824-4.073,4.073c0,2.25,1.824,4.072,4.073,4.072c2.25,0,4.072-1.823,4.072-4.072c0-0.552-0.11-1.077-0.309-1.557
		c7.579-6.311,15.79-11.767,24.514-16.287c-6.469,6.878-17.676,20.073-26.643,37.293c-7.65,14.695-12.056,29.297-13.095,43.401
		c-1.298,17.643,2.692,34.54,11.861,50.219c18.38,31.43,30.516,55.348,38.11,75.119c-3.271,3.904-4.28,9.279-2.623,14.096
		c1.685,4.898,5.896,8.559,10.987,9.557c0.081,0.016,0.161,0.023,0.241,0.023c0.36,0,0.707-0.156,0.947-0.434
		c3.401-3.918,4.467-9.395,2.782-14.293c-1.548-4.498-5.231-7.947-9.767-9.252c-7.63-20.02-19.88-44.217-38.514-76.08
		c-16.213-27.727-15.778-58.409,1.293-91.199c12.33-23.679,29.434-40.063,31.227-41.745c8.767-3.939,17.828-6.912,27.121-8.902
		l-0.001,0.032c10.909,0.548,21.702,2.41,32.097,5.52c0.855,4.916,4.076,9.169,8.636,11.283c2.041,0.946,4.233,1.412,6.421,1.412
		c3.107,0,6.202-0.94,8.821-2.78c0.368-0.257,0.569-0.692,0.528-1.139c-0.493-5.431-3.867-10.227-8.805-12.516
		c-4.888-2.268-10.657-1.772-15.102,1.276c-7.53-2.238-15.263-3.832-23.097-4.758c6.31-0.886,12.713-1.341,19.191-1.341
		c33.025,0,64.397,11.745,89.172,33.229c-13.359-5.488-27.51-8.266-42.174-8.266c-7.81,0-15.599,0.813-23.148,2.415
		c-0.449,0.095-0.811,0.428-0.941,0.869c-0.131,0.439-0.011,0.916,0.313,1.241c9.609,9.654,19.74,27.913,13.431,60.077
		c-0.714,3.63,0.312,7.43,2.742,10.164l15.501,17.477c0.775,0.872,0.769,2.177-0.014,3.034c-2.63,2.884-5.093,5.261-7.119,6.872
		c-1.791,1.416-2.305,3.872-1.237,5.865c1.064,2.509,2.194,5.275,2.711,6.668c-1.175,0.4-3.664,1.129-7.917,2.246
		c-0.764,0.201-1.282,0.895-1.26,1.684c0.022,0.791,0.579,1.455,1.349,1.611c4.055,0.852,6.722,2.287,7.136,3.844
		c0.396,1.484-1.232,3.32-2.667,4.596c-0.989,0.879-2.062,1.615-3.099,2.326c-4.504,3.09-8.758,6.008-4.122,18.363
		c0.728,1.936,0.475,4.033-0.693,5.752c-1.196,1.76-3.104,2.791-5.236,2.83c-6.626,0.129-16.483-0.344-25.768-3.264
		c-2.406-0.756-4.909-0.85-7.309-0.311c2.061-8.498,5.164-24.939,1.279-34.816l-28.747-67.103c-0.244-0.57-0.866-0.875-1.466-0.721
		c-0.293,0.075-2.918,0.849-4.679,4.59c-2.349,4.994-3.016,15.313,5.924,36.431c-5.942-6.215-13.098-13.508-19.942-19.994
		c18.874-25.987,11.687-39.207,11.369-39.762c-0.209-0.364-0.586-0.6-1.006-0.627c-0.417-0.027-0.823,0.157-1.078,0.49l-7.049,9.239
		c8.491-25.378,0.469-30.941,0.094-31.183c-0.29-0.188-0.645-0.247-0.98-0.163c-0.335,0.083-0.622,0.301-0.792,0.603l-5.895,10.487
		c-0.312-14.039-6.883-20.145-7.194-20.425c-0.333-0.299-0.799-0.4-1.224-0.262c-0.426,0.138-0.746,0.492-0.84,0.928L98.997,99.408
		l-1.745,3.114c-0.194,0.347-0.212,0.765-0.049,1.128l40.385,89.49l-10.604,16.557c-0.275,0.43-0.262,0.984,0.034,1.4
		c22.663,31.893,56.923,50.186,93.998,50.186c13.75,0,27.168-2.482,39.865-7.365c0.414-0.15,0.82-0.307,1.198-0.467
		c0.056-0.023,0.112-0.045,0.168-0.068c0.412-0.162,0.823-0.326,1.232-0.496c-24.775,21.488-56.152,33.238-89.182,33.238
		c-32.992,0-64.818-11.941-89.632-33.623c-10.568-12.742-18.648-27.264-23.957-42.754c6.087-0.84,11.347-4.824,13.772-10.471
		c2.467-5.746,1.674-12.434-2.068-17.457c-0.268-0.357-0.708-0.541-1.154-0.492c-6.219,0.742-11.616,4.773-14.083,10.518
		c-0.323,0.756-0.588,1.527-0.8,2.307c-2.01-9.346-3.048-18.93-3.048-28.592c0-7.701,0.652-15.417,1.93-22.945
		c4.737-1.778,8.342-5.823,9.524-10.775c1.264-5.295-0.408-10.915-4.365-14.668c-0.325-0.31-0.792-0.419-1.221-0.291
		c-5.226,1.56-9.257,5.818-10.521,11.112c-1.233,5.165,0.334,10.634,4.083,14.382c-1.281,7.61-1.937,15.406-1.937,23.186
		c0,27.021,7.944,53.449,22.6,75.898c-22.652-24.949-35.4-57.563-35.4-91.613c0-20.557,4.463-40.289,13.251-58.666
		c0.049,0,0.1,0.004,0.149,0.004c7.004,0,13.622-3.544,17.476-9.431c4.064-6.207,4.512-14.186,1.168-20.824
		c-0.202-0.401-0.602-0.664-1.05-0.688c-7.419-0.399-14.556,3.193-18.619,9.399c-3.979,6.078-4.481,13.854-1.361,20.406
		c-2.477,5.176-4.612,10.456-6.411,15.831c-1.788-5.489-4.872-9.488-9.212-11.91C23.239,88.292,10,93.354,9.442,93.573
		c-0.518,0.203-0.841,0.722-0.792,1.276c0.048,0.554,0.457,1.011,1.003,1.12c26.054,5.221,27.767,27.681,27.875,30.099
		c-1.338,7.781-2.016,15.719-2.016,23.778c0,39.992,17.259,78.041,47.352,104.412c0.349,0.42,0.703,0.84,1.057,1.252
		c0.027,0.033,0.057,0.064,0.087,0.096l0.196-0.201c25.093,21.436,57.016,33.229,90.092,33.229
		c36.286,0,70.612-13.924,96.656-39.203c0.442-0.43,0.506-1.117,0.15-1.623C270.747,247.305,270.077,247.133,269.523,247.408z
		 M131.877,244.572c1.315,3.822,0.606,8.07-1.838,11.264c-3.891-1.012-7.064-3.924-8.378-7.746
		c-1.315-3.822-0.606-8.068,1.838-11.262C127.389,237.84,130.563,240.754,131.877,244.572z M192.25,20.666
		c3.906,1.811,6.642,5.499,7.274,9.737c-3.644,2.255-8.226,2.549-12.131,0.739s-6.642-5.499-7.275-9.737
		C183.763,19.149,188.346,18.854,192.25,20.666z M142.53,96.064c0.691-1.537,1.539-2.401,2.176-2.871l28.203,65.833
		c3.642,9.258,0.569,25.223-1.43,33.41c-4.733-18.416-20.221-38.664-33.701-53.591l21.027,5.564
		c0.108,0.028,0.216,0.042,0.322,0.042c0.555,0,1.062-0.371,1.211-0.932c0.178-0.669-0.221-1.355-0.89-1.532l-4.124-1.092
		c-1.129-1.218-2.432-2.615-3.877-4.153C139.377,110.961,140.675,100.193,142.53,96.064z M150.776,139.693l-16.139-4.271
		c-7.139-7.66-13.136-13.291-15.353-15.328c1.976-2.284,3.82-4.559,5.522-6.795C134.232,122.248,144.231,132.703,150.776,139.693z
		 M113.627,43.651c2.117,3.032,5.594,9.891,4.716,21.406l-15.182,26.957L113.627,43.651z M99.748,103.196L120.66,66.05h0
		c0.012-0.022,0.024-0.044,0.035-0.066l7.492-13.326c1.708,2.786,4.434,11.453-4.48,33.894c-0.031,0.077-0.053,0.155-0.067,0.234
		l-19.667,25.769L99.748,103.196z M129.553,210.334l9.265-14.467l9.425,20.885c0.209,0.465,0.666,0.742,1.143,0.742
		c0.173,0,0.348-0.037,0.515-0.113c0.631-0.285,0.912-1.027,0.626-1.656l-45.384-100.575l20.421-26.758
		c0.146-0.062,0.283-0.152,0.399-0.268c0.04-0.039,0.076-0.081,0.11-0.126l10.118-13.26c1.097,4.422,1.859,16.232-12.107,35.345
		c-0.191,0.064-0.371,0.168-0.518,0.326c-0.219,0.232-0.327,0.525-0.335,0.821c-2.006,2.666-4.23,5.399-6.639,8.14
		c-0.454,0.518-0.406,1.305,0.108,1.763c0.071,0.063,7.042,6.302,15.843,15.721c0.109,0.352,0.373,0.645,0.732,0.787
		c14.246,15.362,31.884,37.565,36.181,57.116c-3.07,1.912-5.292,4.842-6.277,8.357c-2.709,9.676-4.786,24.035-0.048,38.947
		C150.37,234.074,138.961,223.391,129.553,210.334z M51.111,128.576c1-4.188,4.072-7.601,8.1-9.063
		c2.933,3.123,4.131,7.557,3.131,11.743c-1,4.188-4.071,7.601-8.1,9.063C51.309,137.196,50.112,132.764,51.111,128.576z
		 M59.479,192.834c2.019-4.699,6.324-8.061,11.349-8.914c2.841,4.23,3.368,9.668,1.349,14.369
		c-2.018,4.699-6.324,8.061-11.348,8.912C57.987,202.971,57.46,197.533,59.479,192.834z M52.491,71.015
		c3.441-5.256,9.397-8.377,15.65-8.295c2.592,5.705,2.098,12.406-1.342,17.662c-3.441,5.256-9.386,8.36-15.65,8.295
		C48.557,82.972,49.05,76.271,52.491,71.015z M14.71,94.661c4.503-0.994,11.677-1.742,17.479,1.496
		c4.665,2.603,7.691,7.396,9.002,14.24c-0.88,2.965-1.659,5.955-2.334,8.972C36.748,111.689,30.898,99.634,14.71,94.661z'
                  className='svg-elem-1'
                ></path>
              </g>
              <g id='Layer_2'>
                <g>
                  <path
                    d='M324.149,134.364L293.354,25.491h19.441l22.708,81.655l22.241-81.655h17.419l22.397,81.811l22.708-81.811h19.442
			l-30.795,108.873H388.54l-22.085-81.344l-21.93,81.344H324.149z'
                    className='svg-elem-2'
                  ></path>
                  <path
                    d='M448.415,28.913c0-6.688,5.132-11.821,11.82-11.821c6.844,0,11.821,5.133,11.821,11.821s-4.977,11.976-11.821,11.976
			C453.547,40.889,448.415,35.601,448.415,28.913z M451.059,134.364V51.932h18.353v82.433H451.059z'
                    className='svg-elem-3'
                  ></path>
                  <path
                    d='M483.874,108.079h17.73c0.622,8.865,8.243,13.531,18.353,13.531c9.332,0,14.309-3.577,14.309-9.954
			c0-6.376-5.288-7.621-12.443-9.954l-10.887-3.577c-13.687-4.51-25.507-9.176-25.507-24.418c0-14.62,11.198-24.263,30.951-24.263
			c19.13,0,32.195,10.109,34.372,28.773h-17.419c-0.933-9.332-7.621-13.687-17.108-13.687c-8.088,0-12.754,3.422-12.754,9.021
			c0,6.377,7.155,8.088,13.065,10.11l9.954,3.266c16.331,5.444,26.13,10.421,26.13,25.196c0,15.242-11.51,24.574-31.729,24.574
			C498.338,136.697,484.962,126.121,483.874,108.079z'
                    className='svg-elem-4'
                  ></path>
                  <path
                    d='M561.483,92.682c0-25.508,15.708-43.238,37.639-43.238c11.665,0,21.308,4.821,27.063,12.598V17.715h18.353v116.649
			h-14.775l-1.711-14.62c-4.666,10.421-14.931,16.953-28.307,16.953C577.502,136.697,561.483,118.655,561.483,92.682z
			 M626.184,92.526c0-16.331-9.332-27.685-22.708-27.685c-13.687,0-23.174,11.509-23.174,27.996
			c0,16.642,9.332,28.307,22.863,28.307S626.184,109.323,626.184,92.526z'
                    className='svg-elem-5'
                  ></path>
                  <path
                    d='M659.153,92.371c0-26.907,18.198-42.927,42.306-42.927c26.596,0,42.149,18.664,42.149,42.927
			c0,27.84-17.575,44.327-42.149,44.327C674.707,136.697,659.153,117.878,659.153,92.371z M724.944,92.371
			c0-16.331-9.177-27.218-23.485-27.218c-14.31,0-23.642,10.887-23.642,27.218c0,18.042,10.11,28.618,23.642,28.618
			C716.234,120.988,724.944,108.857,724.944,92.371z'
                    className='svg-elem-6'
                  ></path>
                  <path
                    d='M757.914,134.364V51.932h14.775l1.4,11.51c5.132-8.088,15.242-13.998,27.529-13.998c11.82,0,20.841,5.91,25.196,15.398
			c4.666-8.71,15.242-15.398,28.307-15.398c17.264,0,28.307,12.287,28.307,30.018v54.903H864.92v-53.97
			c0-9.177-5.6-15.087-14.465-15.087c-12.599,0-20.53,8.243-20.686,19.13v49.926h-18.354v-53.97c0-9.177-5.599-15.087-14.464-15.087
			c-12.599,0-20.53,8.243-20.687,19.13v49.926H757.914z'
                    className='svg-elem-7'
                  ></path>
                  <path
                    d='M917.796,134.364l-24.419-82.433h18.82l16.642,59.725l16.331-59.725h15.242l16.486,59.103l16.331-59.103h18.819
			l-24.419,82.433h-18.975l-15.864-56.303l-15.709,56.303H917.796z'
                    className='svg-elem-8'
                  ></path>
                  <path
                    d='M1018.267,111.501c0.155-19.286,19.286-23.33,35.306-25.196c12.443-1.4,21.619-1.867,21.775-8.399
			c-0.156-8.087-6.377-13.376-16.953-13.376c-10.888,0-18.664,5.911-19.753,14.465h-18.509c1.711-17.42,17.265-29.551,38.572-29.551
			c22.241,0,34.528,12.131,34.528,29.707v37.328c0,2.333,1.244,3.577,4.044,3.577h4.511v14.309h-8.71
			c-8.399,0-13.531-3.422-14.932-9.643c-0.311-0.778-0.622-1.866-0.777-2.799c-4.821,8.865-13.843,14.153-27.841,14.153
			C1030.554,136.075,1018.112,126.898,1018.267,111.501z M1075.348,103.413V91.904c-3.111,4.821-11.043,5.91-19.753,6.999
			c-10.266,1.244-18.819,2.644-18.819,11.354c0,7.154,5.754,11.354,16.486,11.354C1065.86,121.61,1075.348,114.456,1075.348,103.413
			z'
                    className='svg-elem-9'
                  ></path>
                  <path
                    d='M1107.695,165.471v-15.553h14.932c6.843,0,10.576-2.488,12.132-6.843l3.266-8.71h-3.422l-32.662-82.433h19.753
			l23.642,62.524l23.174-62.524h19.131l-35.929,96.586c-4.199,11.043-12.909,16.953-29.084,16.953H1107.695z'
                    className='svg-elem-10'
                  ></path>
                </g>
                <g>
                  <path
                    d='M324.15,269.669l-30.795-108.873h19.441l22.708,81.655l22.241-81.655h17.419l22.397,81.811l22.708-81.811h19.442
			l-30.795,108.873h-20.375l-22.085-81.344l-21.93,81.344H324.15z'
                    className='svg-elem-11'
                  ></path>
                  <path
                    d='M441.885,228.764c0-27.218,17.886-44.016,40.905-44.016c23.33,0,38.883,16.331,39.35,43.083v4.354h-61.902
			c1.244,14.465,10.265,24.263,24.263,24.263c9.487,0,16.642-4.666,19.441-12.909h17.886c-4.199,17.731-18.042,28.463-37.483,28.463
			C459.149,272.002,441.885,253.96,441.885,228.764z M503.165,219.276c-2.333-11.82-9.643-18.975-20.375-18.975
			c-10.887,0-18.975,7.154-21.775,18.975H503.165z'
                    className='svg-elem-12'
                  ></path>
                  <path
                    d='M536.755,269.669V153.02h18.353v116.649H536.755z'
                    className='svg-elem-13'
                  ></path>
                  <path
                    d='M574.391,269.669V153.02h18.354v116.649H574.391z'
                    className='svg-elem-14'
                  ></path>
                  <path
                    d='M611.717,269.669v-82.433h14.775l1.556,12.132c5.133-8.555,15.553-14.62,27.84-14.62c17.575,0,29.085,11.82,29.085,30.329
			v54.592h-18.353V216.01c0-9.176-6.066-15.242-16.331-15.242c-11.977,0-20.063,8.243-20.22,18.976v49.926H611.717z'
                    className='svg-elem-15'
                  ></path>
                  <path
                    d='M698.19,228.764c0-27.218,17.887-44.016,40.905-44.016c23.33,0,38.884,16.331,39.351,43.083v4.354h-61.902
			c1.244,14.465,10.265,24.263,24.263,24.263c9.487,0,16.643-4.666,19.441-12.909h17.887c-4.199,17.731-18.042,28.463-37.483,28.463
			C715.455,272.002,698.19,253.96,698.19,228.764z M759.47,219.276c-2.333-11.82-9.643-18.975-20.375-18.975
			c-10.887,0-18.975,7.154-21.774,18.975H759.47z'
                    className='svg-elem-16'
                  ></path>
                  <path
                    d='M788.551,243.384h17.73c0.622,8.865,8.243,13.531,18.353,13.531c9.332,0,14.31-3.577,14.31-9.954
			s-5.288-7.621-12.442-9.954l-10.888-3.577c-13.687-4.511-25.508-9.177-25.508-24.419c0-14.62,11.199-24.263,30.951-24.263
			c19.131,0,32.195,10.109,34.373,28.773h-17.42c-0.933-9.332-7.621-13.687-17.108-13.687c-8.088,0-12.754,3.422-12.754,9.021
			c0,6.377,7.154,8.088,13.065,10.109l9.954,3.267c16.33,5.443,26.129,10.421,26.129,25.196c0,15.242-11.509,24.574-31.729,24.574
			C803.015,272.002,789.64,261.426,788.551,243.384z'
                    className='svg-elem-17'
                  ></path>
                  <path
                    d='M866.315,243.384h17.73c0.622,8.865,8.243,13.531,18.353,13.531c9.332,0,14.31-3.577,14.31-9.954
			s-5.288-7.621-12.442-9.954l-10.888-3.577c-13.687-4.511-25.508-9.177-25.508-24.419c0-14.62,11.199-24.263,30.951-24.263
			c19.131,0,32.195,10.109,34.373,28.773h-17.42c-0.933-9.332-7.621-13.687-17.108-13.687c-8.088,0-12.754,3.422-12.754,9.021
			c0,6.377,7.154,8.088,13.065,10.109l9.954,3.267c16.33,5.443,26.129,10.421,26.129,25.196c0,15.242-11.509,24.574-31.729,24.574
			C880.779,272.002,867.404,261.426,866.315,243.384z'
                    className='svg-elem-18'
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <motion.div
            className='navbar-items'
            variants={fadeInVideo}
            initial='initial'
            animate='animate'
          >
            <div
              className='navbar-item'
              onClick={() => setShowAboutPage(true)}
              style={{ display: isActive ? 'none' : 'block' }}
            >
              About
            </div>

            <ContactButton
              isActive={isActive}
              setIsActive={setIsActive}
              setOpenForm={setOpenForm}
            />
          </motion.div>
        </div>
      </nav>
      <ContactMenu
        isActive={isActive}
        openForm={openForm}
        setOpenForm={setOpenForm}
        width={width}
        height={height}
      />
    </>
  );
};

export default Navbar;
