const easing = [0.6, -0.05, 0.01, 0.99];

export const transition = {
  duration: 0.6,
};

export const fadeIn = {
  initial: {
    opacity: 0,
    transition: {
      duration: 0.7,
      ease: easing,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.7,
      ease: easing,
    },
  },
  whileInView: {
    opacity: 1,
    transition: {
      delay: 2.5,
      duration: 1.5,
      ease: easing,
    },
  },
};

export const fadeInVideo = {
  initial: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: easing,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: easing,
    },
  },
  animate: {
    opacity: 1,
    transition: {
      delay: 0.8,
      duration: 1,
      ease: easing,
    },
  },
};

export const fadeInServices = {
  initial: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: easing,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: easing,
    },
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 1,
      ease: easing,
    },
  },
};

export const fadeInFooter = {
  initial: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: easing,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: easing,
    },
  },
  animate: {
    opacity: 1,
    transition: {
      delay: 1,
      duration: 1,
      ease: easing,
    },
  },
};

export const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1.5,
      ease: easing,
    },
  },
  whileInView: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: easing,
    },
  },
};

export const fadeInUpDelay = {
  initial: {
    y: 60,
    opacity: 0,
  },
  whileInView: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.3,
      duration: 1,
      ease: easing,
    },
  },
};

export const fadeInUpDelay2 = {
  initial: {
    y: 60,
    opacity: 0,
  },
  whileInView: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.1,
      duration: 1,
      ease: easing,
      staggerChildren: 0.7,
    },
  },
};

export const fadeInUpItem = {
  initial: {
    y: 60,
    opacity: 0,
  },
  whileInView: {
    y: 0,
    opacity: 1,
  },
};

export const fadeInDown = {
  initial: {
    y: -60,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: easing,
    },
  },
  whileInView: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: easing,
    },
  },
};

export const fadeInDownSm = {
  initial: {
    y: -30,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: easing,
    },
  },
  whileInView: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: easing,
    },
  },
};

export const fadeInDownDelay = {
  initial: {
    y: -60,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.8,
      duration: 1,
      ease: easing,
    },
  },
};

export const expandFromLeft = {
  initial: {
    width: 0,
  },
  whileInView: {
    width: '100%',
    transition: {
      // delay: 0.2,
      duration: 2,
      ease: easing,
    },
  },
};

export const navMenuInitial = {
  initial: {
    y: 60,
    opacity: 0,
    transition: {
      duration: 0.7,
      ease: easing,
    },
  },
  exit: {
    y: 60,
    opacity: 0,
    transition: {
      duration: 0.7,
      ease: easing,
    },
  },
  whileInView: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 2.5,
      ease: easing,
    },
  },
};
