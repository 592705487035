import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { fadeInUpDelay, fadeInUpDelay2 } from '../Transition';
import { motion } from 'framer-motion';
import './TestimonialsCarousel.scss';

const testimonials = [
  {
    id: 1,
    quote:
      'Meditation is a tool for the mind to achieve different states of being.',
    name: 'Michael Johnson',
    age: 28,
    therapyType: 'Ketamine Therapy',
  },
  {
    id: 2,
    quote:
      'Meditation is a tool for the mind to achieve different states of being.',
    name: 'Sarah Williams',
    age: 19,
    therapyType: 'PsychoTherapy',
  },
  {
    id: 3,
    quote:
      'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aperiam, quidem?',
    name: 'Frank Masey',
    age: 25,
    therapyType: 'Alcohol',
  },
  {
    id: 4,
    quote:
      'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aperiam, quidem?',
    name: 'John Smith',
    age: 38,
    therapyType: 'Physio Therapy',
  },
];

function TestimonialsCarousel({ bottomHeadingRef, width }) {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: width <= 576 ? 1 : 3,
    slidesToScroll: width <= 576 ? 1 : 3,
  };

  return (
    <div className='carousel-wrapper'>
      <div className='carousel-container'>
        <motion.div className='carousel-heading container'>
          <motion.h2
            variants={fadeInUpDelay2}
            initial='initial'
            whileInView='whileInView'
            viewport={{ once: true }}
          >
            Success
          </motion.h2>
          <motion.h2
            variants={fadeInUpDelay2}
            initial='initial'
            whileInView='whileInView'
            viewport={{ once: true }}
          >
            Stories
          </motion.h2>
        </motion.div>

        <div className='carousel-wrapper'>
          <Slider {...settings}>
            {testimonials?.map((testimonial) => (
              <div className='carousel-details-wrapper' key={testimonial?.id}>
                <h3>{testimonial?.quote}</h3>
                <br />
                <h4>{testimonial?.name}</h4>
              </div>
            ))}
          </Slider>
          {/* <div className='carousel-overlay'></div> */}
        </div>

        <div className='carousel-bottom-heading' ref={bottomHeadingRef}>
          <h1>
            <span>My</span> Services
          </h1>
        </div>
      </div>
    </div>
  );
}

export default TestimonialsCarousel;
